import { Component, Input, OnInit, input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService, ClientEnv, DateUtils, ModulesDTO, TeamMemberDTO } from 'common';

@Component({
    selector: 'hiji-user-card',
    templateUrl: './user-card.component.html',
    styleUrls: ['./user-card.component.scss'],
    standalone: false
})
export class UserCardComponent implements OnInit {
  @Input()
  user: Partial<TeamMemberDTO>;
  /*{
    idUser: number | null;
    firstName: string | null;
    lastName: string | null;
    avatarExtension: string | null;
    functionName: string | null;
    nbObservation?: number | null;
    nbAutoObservation?: number | null;
    lastObservationDate?: Date | null;
    lastAutoObservationDate?: Date | null;
    lastExchangeDate?: Date | null;
    campaignExists?: boolean | null;
    isPDIAvailable?: boolean | null;
    nextObsOrAutoObsHintDateEMA?: Date | null;
    nextEMADate?: Date | null;
    nbObsToDoEMA?: number | null;
    nbObsOrAutoObsEMA?: number | null;
    isTargetedByEMA
  };*/
  @Input()
  showCounters: boolean = false;
  @Input()
  showDates: boolean = false;
  @Input()
  showCallToAction: boolean = false;
  withHover = input(false);
  me: boolean = false;
  modules: ModulesDTO;
  //TODO in future ça devrait etre dans le modules DTO et configurable dans le BO
  nbDaysBetweenExchange: number = 30;
  DateUtils: typeof DateUtils = DateUtils;

  constructor(
    public translate: TranslateService,
    private authService: AuthService,
    public clientEnv: ClientEnv
  ) {}

  ngOnInit(): void {
    if (this.authService.userBundle !== null) {
      this.modules = this.authService.modules;
      if (this.user.idUser === this.authService.userBundle.user.idUser) this.me = true;
    } else {
      //can happen in unlogged 360 answer
      this.modules = new ModulesDTO();
    }
  }

  getDaysSinceLastExchange() {
    let difference = new Date().getTime() - this.user.lastExchangeDate.getTime();
    let totalDays = Math.ceil(difference / (1000 * 3600 * 24));
    return totalDays;
  }

  getCardStyle() {
    if (this.user.rank === 0) return null;
    const colors = ['', 'var(--hiji-rank-1)', 'var(--hiji-rank-2)', 'var(--hiji-rank-3)'];
    return colors[this.user.rank] + ' !important';
  }
  getOutlineStyle() {
    if (this.user.rank === 0) return null;
    return '4px solid ' + this.getCardStyle();
  }
}
