import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { Ic2Module } from '@ic2/ic2-lib';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { HijiCommonModule, TRANSLATE_CHILD_CONFIG } from 'common';
import { MomentModule } from 'ngx-moment';
import { UserCardComponent } from './user-card.component';
@NgModule({
  declarations: [UserCardComponent],
  imports: [
    CommonModule,
    MomentModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    HijiCommonModule,
    Ic2Module,
    TranslateModule.forChild(TRANSLATE_CHILD_CONFIG),
    RouterModule.forChild([]),
  ],
  exports: [UserCardComponent],
})
export class UserCardModule {}
